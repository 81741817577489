// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

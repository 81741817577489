// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

@import 'vars';
@import 'mixins';

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	height: 100%;
	scroll-behavior: smooth;
	background-color: $main-black-color;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1;
	font-weight: 400;
	height: 100%;
	overflow-x: hidden;
	color: $main-black-color;
	background-color: $main-gray-color;

	@include media-laptop {
		font-size: 14px;
	}

	@include media-mobile {
		font-size: 12px;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	cursor: pointer;
}

#root {
	height: 100%;
}

ul,
ol {
	list-style-type: none;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-button {
	height: 0;
	width: 100%;
}

::-webkit-scrollbar-thumb {
	background-color: $main-gray-color;
	border-radius: 50px;
}

::-webkit-scrollbar-track-piece {
	background-color: $main-black-color;
}

details > summary {
	list-style: none;
}

details > summary::-webkit-details-marker {
	display: none;
}

// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  min-height: 100vh;
  padding-top: 140px;
  padding-bottom: 250px;
  @include block-padding(28px);
  background: $main-black-color url('../../assets/waves_static.jpg') no-repeat center / 100%;
  background-size: cover;
  position: relative;

  @include media-laptop {
    @include block-padding(42px);
    padding-top: 309px;
    padding-bottom: 309px;
    min-height: auto;
  }

  @include media-mobile {
    @include block-padding(16px);
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.heading {
  transform: translateY(160px);
  transition: transform 1s ease-in-out;
}

.description {
  margin-top: 100px;
  margin-bottom: 200px;
  max-width: 825px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: $full-white-color;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;

  @include media-laptop {
    margin-bottom: 150px;
    max-width: 460px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  @include media-mobile {
    margin-bottom: 50px;
    margin-top: 50px;
    max-width: 328px;
    font-size: 14px;
    line-height: 17px;
  }
}

.columns {
  @include flex-justify(space-between);
  min-height: 218px;

  @include media-laptop {
    flex-direction: column;
    gap: 50px;
  }

  @include media-mobile {
    gap: 24px;
  }

  & li {
    width: 33%;
    gap: 20px;
    opacity: 0;

    @include media-laptop {
      width: 100%;
      max-width: 460px;
      gap: 0;
    }

    &:nth-child(2) {
      align-self: center;

      @include media-mobile {
        align-self: baseline;
      }
    }

    &:last-child {
      align-self: flex-end;

      @include media-mobile {
        align-self: baseline;
      }
    }
  }

  & p {
    font-size: 14px;
    line-height: 17px;
    color: $full-white-color;

    @include media-mobile {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.active {
  & .heading {
    transform: translateY(0);
    transition: transform 1s ease-in-out;
  }

  & .description {
    opacity: 1;
    transition: opacity 2.5s ease-in-out;
  }

  & .columns li {
    opacity: 1;

    &:first-of-type {
      transition: opacity 4s ease-in-out;
    }

    &:nth-child(2) {
      transition: opacity 6s ease-in-out;
    }

    &:last-of-type {
      transition: opacity 8s ease-in-out;
    }
  }
}

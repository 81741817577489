// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  display: flex;
  padding-bottom: 200px;
  @include block-padding(28px);
  background-color: $main-gray-color;
  position: relative;
  z-index: 2;
  overflow-y: hidden;

  @include media-laptop {
    padding-top: 0;
    padding-bottom: 150px;
    @include block-padding(42px);
  }

  @include media-mobile {
    padding-bottom: 100px;
    @include block-padding(16px);
    flex-direction: column;
    align-items: baseline;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 621px;
    height: 919px;
    background: url('../../../../../assets/images/patterns/patterns_left-side.svg') no-repeat center / 100%;
    pointer-events: none;
    z-index: 1;

    @include media-laptop {
      top: 50px;
      width: 322px;
      height: 476px;
    }

    @include media-mobile {
      display: none;
    }
  }
}

.description {
  margin-bottom: 50px;
  max-width: 544px;
  font-size: 14px;
  line-height: 17px;
  white-space: break-spaces;

  @include media-laptop {
    max-width: 460px;
  }

  @include media-mobile {
    font-size: 12px;
    line-height: 15px;
    margin-top: 24px;
  }
}

.infoBlock {
  width: 50%;
  margin-left: auto;

  @include media-laptop {
    width: 60%;
  }

  @include media-mobile {
    width: 100%;
  }
}

.list {
  max-width: 544px;

  @include media-laptop {
    max-width: 460px;
  }
}

// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  background-color: $main-black-color;
  padding-top: 228px;
  margin-top: 10px;
  position: relative;

  @include media-laptop {
    padding-top: 150px;
  }

  @include media-mobile {
    padding-top: 100px;
  }
}

.heading {
  max-width: 847px;
  position: relative;

  @include media-laptop {
    max-width: 565px;
  }

  @include media-mobile {
    max-width: 283px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -278px;
    left: calc((100% - 360px) / 2);
    width: 360px;
    height: 248px;
    background: url('../../assets/selectedWorks/patterns_selected-works.svg') no-repeat center / 100%;

    @include media-mobile {
      bottom: -168px;
      left: calc((100% - 220px) / 2);
      width: 220px;
      height: 152px;
    }
  }
}

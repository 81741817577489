// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  display: flex;
  padding-top: 340px;
  padding-bottom: 68px;
  @include block-padding(28px);
  background-color: $main-black-color;
  position: relative;
  z-index: 2;

  @include media-laptop {
    @include block-padding(42px);
    padding-bottom: 345px;
    flex-direction: column;
  }

  @include media-mobile {
    @include block-padding(16px);
    padding-top: 251px;
    padding-bottom: 134px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: calc((100% - 624px) / 2);
    width: 624px;
    height: 430px;
    background: url('../../assets/contacts/patterns_contacts.svg') no-repeat center / 100%;
    pointer-events: none;
    z-index: 1;

    @include media-mobile {
      top: 2px;
      left: calc((100% - 364px) / 2);
      width: 364px;
      height: 251px;
    }
  }
}

.description {
  margin-left: auto;
  max-width: 544px;
  width: 100%;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.2;
  color: $full-white-color;

  @include media-laptop {
    max-width: 458px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-left: 120px;
  }

  @include media-mobile {
    font-size: 14px;
    line-height: 17px;
    margin-left: 0;
  }
}

.links {
  width: 50%;
  margin-top: 290px;
  padding-left: 20px;

  @include media-laptop {
    margin-top: 200px;
    margin-left: 50%;
    padding-left: 0;
  }

  @include media-mobile {
    @include flex-align(center);
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-top: 50px;
  }

  &-item {
    @include media-laptop {
      font-size: 24px;
      line-height: 29px;

      &:after {
        top: calc((100% - 23px) / 2);
        height: 23px;
      }
    }

    @include media-mobile {
      font-size: 18px;
      line-height: 22px;

      &:after {
        top: calc((100% - 18px) / 2);
        height: 18px;
      }
    }
  }

  & li {
    @include media-mobile {
      min-width: 203px;
      width: fit-content;
      margin-right: -60px;
    }

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
}

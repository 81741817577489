// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  margin-top: 326px;
  @include block-padding(28px);

  @include media-laptop {
    @include block-padding(42px);
  }

  @include media-mobile {
    margin-top: 184px;
    @include block-padding(16px);
  }
}

.findMore {
  padding-bottom: 180px;
  margin-top: 100px;

  @include media-laptop {
    padding-bottom: 140px;
    margin-top: 50px;
  }

  &-text {
    max-width: 544px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: $full-white-color;
    margin-bottom: 106px;

    @include media-laptop {
      max-width: 380px;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 50px;
    }
  }

  &-links {
    @include flex-align(center);
    gap: 164px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    @include media-laptop {
      gap: 80px;
    }

    @include media-mobile {
      flex-direction: column;
      width: auto;
      margin-left: 35px;
      gap: 24px;
    }
  }
}

.seeMore {
  display: block;
  margin: 100px auto 0;
  padding-bottom: 200px;
  width: fit-content;

  @include media-laptop {
    margin-top: 50px;
    padding-bottom: 150px;
  }

  @include media-mobile {
    padding-bottom: 100px;
  }

  &-item {
    background-color: transparent;
    border: 0;
    min-width: 255px;

    @include media-laptop {
      min-width: 175px;
    }

    @include media-mobile {
      min-width: 144px;
    }
  }
}

// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  @include flex-justify-align(center, center);
  flex-direction: column;
  background-color: $main-black-color;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc((100% - 430px) / 2);
    width: 624px;
    height: 430px;
    pointer-events: none;
    z-index: 1;

    @include media-laptop {
      top: calc((100% - 600px) / 2);
      width: 406px;
      height: 600px;
    }

    @include media-mobile {
      top: calc((100% - 285px) / 2);
      width: 130px;
      height: 166px;
    }
  }

  &:before {
    background: url('../../assets/patterns_left-side--white.svg') no-repeat center / 100%;
    left: 0;
  }

  &:after {
    background: url('../../assets/patterns_right-side--white.svg') no-repeat center / 100%;
    right: 0;
  }
}

.title {
  font-family: 'Syne', sans-serif;
  font-weight: 800;
  font-size: 90px;
  line-height: 108px;
  color: $full-white-color;
  text-transform: uppercase;
  margin-bottom: 100px;

  @include media-laptop {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 50px;
  }

  @include media-mobile {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 50px;
  }
}

.description {
  max-width: 544px;
  width: 100%;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: $full-white-color;
  margin-bottom: 50px;
  text-align: center;

  @include media-laptop {
    max-width: 380px;
    font-size: 18px;
    line-height: 22px;
  }

  @include media-mobile {
    max-width: 328px;
    font-size: 14px;
    line-height: 17px;
  }
}

// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  @include flex-justify(center);
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: $main-gray-color;
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc((100% - 919px) / 2);
    width: 621px;
    height: 919px;
    pointer-events: none;
    z-index: 1;

    @include media-laptop {
      top: calc((100% - 600px) / 2);
      width: 406px;
      height: 600px;
    }

    @include media-mobile {
      display: none;
    }
  }

  &:before {
    background: url('../../../../assets/images/patterns/patterns_left-side.svg') no-repeat center / 100%;
    left: 0;
  }

  &:after {
    background: url('../../assets/patterns_right-side.svg') no-repeat center / 100%;
    right: 0;
  }

  & li {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;

    &:not(:last-of-type) {
      margin-bottom: 50px;
    }
  }
}

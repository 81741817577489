// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  color: $main-black-color;
  position: relative;
  padding-bottom: 8px;
  width: fit-content;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: all 0.5s linear;

  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 0;
    transition: all 0.5s ease-in-out;
  }

  &:before {
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $main-black-color;
  }

  &:after {
    top: 7px;
    width: 10px;
    height: 7px;
    background: url('../../assets/textLink/arrow_right_icon--black-thin.svg') no-repeat center / 100%;
  }

  &:hover {
    padding-right: 30px;
    transition: all 0.5s ease-in-out;

    &:before {
      width: 0;
      transition: all 0.5s ease-in-out;
    }

    &:after {
      width: 16px;
      background: url('../../assets/textLink/arrow_right_icon--black-long-thin.svg') no-repeat center / 100%;
      transition: all 0.5s ease-in-out;
    }
  }

  &--md {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.2;
    padding-right: 27px;

    @include media-laptop {
      font-size: 18px;
    }

    @include media-mobile {
      font-weight: 700;
      font-size: 14px;
    }

    &:after {
      top: 12px;
      width: 16px;
      height: 12px;
      background-image: url('../../assets/textLink/arrow_right_icon--white-medium.svg');

      @include media-laptop {
        top: 5px;
      }

      @include media-mobile {
        top: 3px;
      }
    }

    &:hover {
      padding-right: 40px;

      &:before {
        width: 0;
      }

      &:after {
        width: 24px;
        background-image: url('../../assets/textLink/arrow_right_icon--white-long-medium.svg');
      }
    }
  }

  &--sm {
    font-size: 16px;
    line-height: 20px;
    padding-right: 18px;

    @include media-laptop {
      font-size: 14px;
      line-height: 17px;
      padding-bottom: 5px;
    }

    @include media-mobile {
      font-size: 12px;
      line-height: 15px;
    }

    &:after {
      @include media-laptop {
        top: 6px;
      }

      @include media-mobile {
        top: 5px;
      }
    }
  }

  &--xs {
    font-size: 14px;
    line-height: 17px;
    padding-right: 18px;

    &:after {
      top: 5px;
    }
  }

  &--white {
    color: $full-white-color;

    &:before {
      height: 2px;
      background-color: $full-white-color;
    }

    &:after {
      background-image: url('../../assets/textLink/arrow_right_icon--white-medium.svg');
    }

    &:hover {
      &:after {
        background-image: url('../../assets/textLink/arrow_right_icon--white-long-medium.svg');
      }
    }
  }
}

// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
  background-color: $main-gray-color;
  transition: width 0.7s linear;
  animation-name: loadAsideBar;
  animation-duration: 2s;

  &--close:before {
    content: '';
    position: absolute;
    bottom: 28px;
    right: 22px;
    height: 60px;
    width: 6px;
    background-image: url('../../assets/arrow_down_icon.svg');
    @include floating-animation;
  }
}

@keyframes loadAsideBar {
  0% {
    height: 0;
    transition: height 1s ease-in-out;
  }
  100% {
    height: 100vh;
    transition: height 1s ease-in-out;
  }
}

.shortHeading {
  position: absolute;
  top: 75px;
  right: -28px;
  width: 120px;
  height: 34px;
  font-family: 'Syne', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: $secondary-gray-color;
  transform: rotate(-90deg);
  animation-name: loadShortHeading;
  animation-duration: 4s;

  @include media-mobile {
    font-size: 12px;
    line-height: 14px;
    right: -43px;
    top: 60px;
  }
}

@keyframes loadShortHeading {
  0% {
    color: transparent;
    transition: color 1s ease-in-out;
  }
  50%,
  100% {
    color: $secondary-gray-color;
    transition: color 1s ease-in-out;
  }
}

.fullInfo {
  width: 804px;
  margin: auto;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;

  @include media-laptop {
    max-width: 700px;
    width: 100%;
  }

  @include media-mobile {
    max-width: 328px;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &-hello {
    margin-bottom: 20px;
  }

  &-title {
    margin-top: 84px;
    margin-bottom: 119px;

    @include media-laptop {
      margin-bottom: 100px;
      margin-top: 50px;
    }

    @include media-mobile {
      margin-bottom: 50px;
    }
  }

  &-about {
    margin-bottom: 45px;

    @include media-laptop {
      margin-bottom: 28px;
    }
  }
}

.step1,
.step2,
.step3,
.step4,
.step5,
.step6 {
  opacity: 0;
  animation-duration: 1.5s;
  animation-name: appearance;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.step1 {
  animation-delay: 1.5s;
}

.step2 {
  animation-delay: 2s;
}

.step3 {
  animation-delay: 1s;
}

.step4 {
  animation-delay: 2.5s;
}

.step5 {
  animation-delay: 3s;
}

.step6 {
  animation-delay: 3.5s;
}

@keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  width: 100%;
  height: 100%;
  background-color: $full-white-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 30px;

  @include media-laptop {
    top: 17px;
    right: 30px;
  }

  @include media-mobile {
    right: 5px;
    top: 6px;
  }
}

.carousel {
  min-height: 100vh;

  &-inner {
    position: relative;
  }

  &-item {
    display: flex;
    overflow: hidden;
    height: 100vh;

    @include media-laptop {
      flex-direction: column;
      justify-content: space-between;
      overflow: scroll;
      height: calc(100vh + 5px);
    }

    &:hover {
      cursor: url('../../../../common/assets/cursor/cursor_carousel.svg') 50 50,
      pointer;
    }
  }
}

.info {
  width: 50%;
  @include block-padding(20px);
  padding-bottom: 20px;
  position: relative;
  display: flex;

  @include media-laptop {
    width: 100%;
    flex-direction: column;
    @include block-padding(42px);
    padding-bottom: 28px;
  }

  @include media-laptop {
    @include block-padding(16px);
    padding-bottom: 24px;
  }

  &-inner {
    max-width: 544px;
    width: 100%;
    margin-left: 190px;
    margin-top: auto;

    @include media-laptop {
      max-width: 580px;
      margin-left: 0;
    }
  }
}

.textGroup {
  margin-top: 50px;

  @include media-laptop {
    margin-top: 28px;
  }

  @include media-mobile {
    margin-top: 24px;
  }

  &-subtitle {
    font-family: 'Syne', sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: $main-black-color;
    margin-bottom: 10px;

    @include media-mobile {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 13px;
    }

    &:first-child {
      color: $secondary-gray-color;
    }
  }

  &-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    @include media-mobile {
      font-size: 12px;
      line-height: 15px;
    }

    &:first-of-type {
      height: 154px;
      color: $secondary-gray-color;
      margin-bottom: 95px;

      @include media-laptop {
        margin-bottom: 28px;
      }

      @include media-mobile {
        height: 100px;
        margin-bottom: 24px;
      }
    }

    &:last-of-type {
      height: 210px;

      @include media-laptop {
        height: 195px;
      }
    }
  }
}

.number {
  font-family: 'Syne', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  width: 50%;
  position: absolute;
  left: 28px;
  top: 0;
  color: $secondary-gray-color;

  @include media-laptop {
    left: 42px;
  }

  @include media-mobile {
    top: 9px;
    left: 17px;
    font-size: 18px;
    line-height: 22px;
  }
}

.title {
  font-family: 'Syne', sans-serif;
  font-weight: 800;
  font-size: 60px;
  line-height: 72px;
  writing-mode: tb-rl;
  transform: rotate(180deg);
  position: absolute;
  top: 0;
  height: 100%;
  padding-bottom: 50px;

  @include media-laptop {
    margin-top: 100px;
    font-size: 40px;
    line-height: 48px;
    min-height: 96px;
    margin-bottom: 50px;
    padding-bottom: 0;
    transform: rotate(0);
    position: relative;
    height: fit-content;
    writing-mode: unset;
  }

  @include media-mobile {
    font-size: 22px;
    line-height: 26px;
    min-height: 50px;
  }
}

.posters {
  width: 50%;
  position: relative;
  padding-right: 20px;
  padding-left: 70px;

  @include media-laptop {
    width: 100%;
    padding-left: 20px;
  }

  @include media-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  &-item {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.postersCarousel {
  @include flex-align(center);
  max-width: 700px;

  @include media-laptop {
    height: 400px;
    margin: 100px auto;
  }

  @include media-mobile {
    height: auto;
    min-height: 203px;
    margin: 20px auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Breakpoints use in mixins
$low-mobile-bp: 360px;
$mobile-bp: 1024px;
$laptop-bp: 1300px;

// Colors
$main-black-color: #0c0c0c;
$main-gray-color: #f0f0f0;
$secondary-gray-color: #7c7c7c;
$full-black-color: #000;
$full-white-color: #fff;
$next-block-blur: linear-gradient(
	360deg,
	rgba(46, 46, 46, 0.16) -25.3%,
	rgba(176, 176, 176, 0) 91.96%
);

// Flex
@mixin flex-align($align-position) {
	display: flex;
	align-items: $align-position;
}

@mixin flex-justify($justify-position) {
	display: flex;
	justify-content: $justify-position;
}

@mixin flex-justify-align($justify-position, $align-position) {
	display: flex;
	justify-content: $justify-position;
	align-items: $align-position;
}

// Breakpoint mixins
@mixin media-laptop {
	@media (max-width: $laptop-bp) {
		@content;
	}
}

@mixin media-mobile {
	@media (max-width: $mobile-bp) {
		@content;
	}
}

@mixin media-low-mobile {
	@media (max-width: $low-mobile-bp) {
		@content;
	}
}

//Block paddings

@mixin block-padding($padding) {
	padding-left: $padding;
	padding-right: $padding;
}

@mixin floating-animation {
	animation: floating 3s infinite ease-in-out;

	@keyframes floating {
		0% {
			transform: translate(0, 0px);
		}
		50% {
			transform: translate(0, 15px);
		}
		100% {
			transform: translate(0, -0px);
		}
	}
}

.container {
  display: flex;
  margin-top: 10px;
  padding-top: 130px;
  padding-bottom: 200px;
  @include block-padding(28px);
  background-color: $main-black-color;
  position: relative;

  @include media-laptop {
    @include block-padding(42px);
    padding-top: 150px;
    padding-bottom: 157px;
  }

  @include media-mobile {
    @include block-padding(16px);
    padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column;
    align-items: baseline;
  }
}

.infoBlock {
  width: 50%;
  margin-left: auto;

  @include media-laptop {
    width: 60%;
  }

  @include media-mobile {
    width: 100%;
    margin-top: 24px;
  }
}

.carousel {
  margin-top: 100px;
  width: 544px;

  @include media-laptop {
    width: 310px;
    margin-top: 50px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 20px;
  max-width: 544px;
  margin-bottom: 100px;

  @include media-laptop {
    row-gap: 28px;
    margin-bottom: 50px;
    max-width: 460px;
  }

  @include media-laptop {
    row-gap: 24px;
    column-gap: 10px;
    margin-bottom: 40px;
  }
}
